import bg1 from "../images/shapes/14.jpg";
import bg2 from "../images/shapes/26.jpg";
import bg3 from "../images/shapes/44.jpg";
import bg4 from "../images/shapes/213.jpg";
import me from "../images/dp/abdullah.jpeg";


const images = {
  bg1,
  bg2, 
  bg3,
  bg4, 
  me,
};

export default images;